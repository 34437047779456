@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



/* POPUP STYLE */


.react-responsive-modal-modal {
  max-width: 90% !important;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 0px !important;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

.chart-container {
  width: 100%;
  height: 100%;
  overflow: visible; /* Ensure labels won't get cut off */
  position: relative;
}


/* Phone Input  Style */

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border-bottom: 1px solid #CACACA !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
}

.react-tel-input .form-control:focus {
  border-bottom: 2pt solid #F79F15 !important;
}

/* Phone Input Flage Dropdown Style */

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border-top: 0px !important;
  border-left: 0px !important;
  border-radius: 0px !important;
}

/* TINDER CSS */
@media (width: 375px){
  .dd {
    position: absolute;
    will-change: transform;
    left: 0;
    right: 0;
    margin-top: 50px;
  }

  .dd>div {
    justify-content: center;
    align-items: center;
    border: 1pt solid lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 500px !important;
    max-width: 100%;
    height: 350px;
    max-height: 100%;
    display: flex;
    will-change: transform;
    border-radius: 20px;
  }

  .mobile_view{
    height: 400px;
  }

  .deck {
    max-width: 400px !important;
  }
}
@media (width: 360px){
  .dd {
    position: absolute;
    will-change: transform;
    left: 0;
    right: 0;
    margin-top: 50px;
  }

  .dd>div {
    justify-content: center;
    align-items: center;
    border: 1pt solid lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 500px !important;
    max-width: 100%;
    height: 400px;
    max-height: 100%;
    display: flex;
    will-change: transform;
    border-radius: 20px;
  }

  .deck {
    max-width: 400px !important;
  }
}

@media (width: 390px){
  .dd {
    position: absolute;
    will-change: transform;
    left: 0;
    right: 0;
    margin-top: 50px;
  }

  .dd>div {
    justify-content: center;
    align-items: center;
    border: 1pt solid lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 500px !important;
    max-width: 100%;
    height: 500px;
    max-height: 100%;
    display: flex;
    will-change: transform;
    border-radius: 20px;
  }

  .deck {
    max-width: 400px !important;
  }
}

@media (min-width: 400px) and (max-width: 480px) {
  .dd {
    position: absolute;
    will-change: transform;
    left: 0;
    right: 0;
    margin-top: 50px;
  }

  .dd>div {
    justify-content: center;
    align-items: center;
    border: 1pt solid lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 500px !important;
    max-width: 100%;
    height: 500px;
    max-height: 100%;
    display: flex;
    will-change: transform;
    border-radius: 20px;
  }

  

  .deck {
    max-width: 400px !important;
  }
}



@media (min-width: 768px) and (max-width: 3000px) {
  .dd {
    position: absolute;
    will-change: transform;
    margin-left: 70px;
    /* margin-top: 50px; */
  }

  .dd>div {
    border: 1pt solid lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 60vh;
    max-width: 500px;
    height: 85vh;
    max-height: 570px;
    display: flex;
    will-change: transform;
    border-radius: 20px;

  }
  

}

@media screen and (width: 360px) {
  .signIn_screen {
    max-width: 100%;
    width: 300px;
  }
}

@media screen and (min-width: 400px) and (max-width:436px) {
  .signIn_screen {
    max-width: 100%;
    width: 700px;
  }
}

.signIn_screen {
  width: 700px;
  max-width: 100%;
}

/* Responsive styles */
@media screen and (min-width: 436px) and (max-width: 768px) {
  .signIn_screen {
    max-width: 100%;
    width: 600px;
  }
  .signUp_screen{
    max-width: 100%;
    width: 600px;
  }
}





.tinder-icon {
  width: 80px;
  height: 80px;
  display: block;
  position: absolute;
  top: 50%;
  z-index: 9999;
  padding: 15px;

}

.tinder-icon.interest {
  top: 0px;
  left: 40%;
}

.tinder-icon.reject {
  top: 0px;
  left: 45%;

}



@media screen and (width:1200px) {
  body {
    background-color: red;
  }
}


/* Pdf view*/

.react-pdf__Page__annotations {
  height: auto !important;
}


.error-bg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23f0b608' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23e6d710' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23e7af05' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23e7d808' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23d8a408' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23f1e213' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23f0b607' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23e4d506' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23eab822' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23e8da14' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23e8b008' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23edde14' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
}

.tracking-tighter-less {
  letter-spacing: -0.75rem;
}

.text-shadow {
  text-shadow: -8px 0 0 rgb(102 123 242);
}

input[type="date"]::-webkit-clear-button {
  display: none !important;
}

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}


.mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mySwiper img {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  object-fit: cover;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-container {
  width: 100%;
  height: auto;
  /* This allows the height to be managed dynamically */
}


.pdf-viewer-container {
  width: 100%;
  max-width: 100%;
  /* padding: 16px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-document {
  width: 100%;
}

.controls {
  margin-top: 16px;
  text-align: center;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.button {
  font-weight: 600;
  margin: 0 8px;
  padding: 8px 16px;
  background-color: #F79F15;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #e6890e;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 600px) {
  .pdf-viewer-container {
    padding: 8px;
  }

  .button {
    padding: 6px 12px;
    margin: 0 4px;
  }
}


/* Multi range slider */
/* price range slider */
.multi-range-slider {
  background-color: gray !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0px !important;
  width: 50%;
}

.bar {
  height: 5px !important;
  box-shadow: none !important;
}

.multi-range-slider .bar-left {
  height: 5px !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.multi-range-slider .bar-right {
  height: 5px !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.multi-range-slider .thumb {
  background-color: #B913F0 !important;

}

.multi-range-slider .thumb::before {
  box-shadow: none !important;
  border: none !important;
}